import React from 'react';
import '../styles/main.css';

function ReservationAppartement() {
  return (
    <div className='iframe-reservation-appart'> 
      <iframe 
        src="https://docs.google.com/forms/d/e/1FAIpQLSciJXQiHhr1QuJ_Gx17ATtIbE5FxldFAXHSGBTw_cxO6xs4Mw/viewform?embedded=true" 
        width="100%" 
        height="100%" 
        title="Formulaire de réservation appartement spa"
        sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
      >
          Chargement…
      </iframe>
    </div>
  );
}

export default ReservationAppartement;