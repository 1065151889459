import React from 'react';
import { Button, Card } from 'react-bootstrap';
import '../styles/reservation.css';
import '../styles/main.css';

function Reservation() {
  return (
    <div className="reservation-container">
      <Card className="card">
        <Card.Img className='card-img' variant="top" src="/images/installations-domicile/installation1.jpg" />
        <Card.Body>
          <Card.Title className="card-title">Spa à domicile</Card.Title>
          <Card.Text className='card-text1'>
            Offrez-vous une parenthèse de bien-être à domicile ! Réservez notre service de spa à domicile pour une expérience de détente inoubliable.
          </Card.Text>
          <Button href="reservation-domicile" className="card-button">Réserver !</Button>
        </Card.Body>
      </Card>
      <Card className="card">
        <Card.Img className='card-img' variant="top" src="../../images/appartement-spa/appart3.JPEG" />
        <Card.Body>
          <Card.Title className="card-title">Appartement spa</Card.Title>
          <Card.Text className='card-text2'>
            Découvrez le summum du luxe et de la détente ! Réservez notre appartement avec spa et sauna intégrés pour une escapade inoubliable où le bien-être prend tout son sens.
          </Card.Text>
          <Button href="/reservation-appartement" className="card-button">Réserver !</Button>
        </Card.Body>
      </Card>
    </div>
  );
}

export default Reservation;