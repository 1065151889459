import React from 'react';
import { Navbar, Container, Nav, Button } from 'react-bootstrap';
import '../styles/components/header.css';
import '../styles/main.css';


function Header() {
  return (
    <Navbar className="bg-body-tertiary" expand="lg">
      <Container>
        <Navbar.Brand href="/">
          <img
            src="logo.svg"
            width="90"
            height="90"
            className="d-inline-block align-top"
            alt="Logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarNavDropdown"/>
        <Navbar.Collapse id="navbarNavDropdown" className="justify-content-end">
            <Nav>
              <Nav.Link href="/spa-domicile" className='nav-link'>
                <img src="/images/icons/home.png" alt="Icon Spa à domicile" className='icon1'/> Spa à domicile
              </Nav.Link>
              <Nav.Link href="/spa-appartement" className='nav-link'>
                <img src="images/icons/jacuzzi.png" alt="Icon Appartement spa" className='icon2'/> Appartement spa
              </Nav.Link>
              <Nav.Link href="/faq" className='nav-link'>
                <img src="images/icons/faq.png" alt="Icon FAQ" className='icon1'/> FAQ
              </Nav.Link>
              <Nav.Link href="/contact" className='nav-link'>
                <img src="images/icons/telephone.png" alt="Icon Contact" className='icon2'/> Contact
              </Nav.Link>
              <Button href="/reservation" className="button">Réserver</Button>
            </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;