import React from 'react';
import '../styles/main.css';

function Contact() {
  return (
    <div className='iframe-contact'>
      <iframe 
        src="https://docs.google.com/forms/d/e/1FAIpQLScOZ97GaVYZJHoeRFAnzy5HqAGn0ZmIo8XMX52Abo6sJUOYrQ/viewform?embedded=true" 
        width="100%" 
        height="100%" 
        title="Formulaire de contact"
        sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
      >
          Chargement...
      </iframe>

    </div>
  );
}

export default Contact;