import React, { useEffect, useState } from 'react';
import SupplementCard from './SupplementCard.tsx';
import '../styles/components/supplements.css';
import '../styles/main.css';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';

const SupplementsSection: React.FC = () => {
    const supplementsData = [
        {
            image: '../../images/supplements/fontaine_chocolat.jpg',
            title: 'Fontaine de chocolat',
            text: 'Composé de brochettes de bonbons ou de fruits',
            price: '20€',
        },
        {
            image: '../../images/supplements/machine_barbe_a_papa.jpg',
            title: 'Machine à barbe à papa',
            text: '',
            price: '15€',
        },
        {
            image: '../../images/supplements/machine_pop_corn.jpg',
            title: 'Machine à pop corn',
            text: '',
            price: '15€',
        },

        {
            image: '../../images/supplements/bar_flottant.jpg',
            title: 'Bar flottant garni',
            text: 'jus, assortiment salé et sucré...',
            price: '20€',
        },
        {
            image: '../../images/supplements/buffet_sale.png',
            title: 'Buffet salé',
            text: 'assortiment de petits-fours',
            price: '20€',
        },
        {
            image: '../../images/supplements/buffet_sucre.avif',
            title: 'Buffet sucré',
            text: 'assortiment de pâtisseries',
            price: '20€',
        },
        {
            image: '../../images/supplements/bouquet_fleurs.png',
            title: 'Bouquet de fleurs',
            text: '',
            price: 'Sur devis',
        },
        {
            image: '../../images/supplements/spa_pour_pieds.jpg',
            title: 'Spa pour pieds',
            text: '',
            price: '10€',
        },
        {
            image: '../../images/supplements/videoprojecteur.png',
            title: 'Vidéoprojecteur',
            text: '',
            price: '20€',
        },
        {
            image: '../../images/supplements/lanterne_volante.png',
            title: 'Lanterne volante',
            text: '',
            price: '20€',
        },
        {
            image: '../../images/supplements/ballons_helium.jpg',
            title: 'Ballons à l’hélium',
            text: '',
            price: '5€/unité',
        },
        {
            image: '../../images/supplements/table-massage.avif',
            title: 'Table de massage',
            text: 'Deux fioles d\'huile comprises',
            price: '10€',
        }
    ];

    const [startIndex, setStartIndex] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(4); // Nombre de suppléments par page

    const nextSupplement = () => {
        setStartIndex((prevIndex) => {
            const newIndex = prevIndex + itemsPerPage;
            return newIndex >= supplementsData.length ? 0 : newIndex;
        });
    };

    const prevSupplement = () => {
        setStartIndex((prevIndex) => {
            const newIndex = prevIndex - itemsPerPage;
            if (newIndex < 0) {
                const remainder = supplementsData.length % itemsPerPage;
                return remainder === 0 ? supplementsData.length - itemsPerPage : supplementsData.length - remainder;
            }
            return newIndex;
        });
    };

    const visibleSupplements = supplementsData.slice(startIndex, startIndex + itemsPerPage).map((supplement, index) => (
        <SupplementCard key={index} {...supplement} />
    ));

    useEffect(() => {
        function handleResize() {
            if (window.innerWidth <= 768) {
                setItemsPerPage(1); // Téléphones
            } else {
                setItemsPerPage(3); // Ordinateurs
            }
        }

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className="supplements-section">
            <FaArrowLeft onClick={prevSupplement} />
            {visibleSupplements}
            <FaArrowRight onClick={nextSupplement} />
        </div>
    );
};

export default SupplementsSection;