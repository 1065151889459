import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css'; 
import 'bootstrap/dist/css/bootstrap.css';
import './styles/main.css';
import './styles/accueil.css';
import Header from './components/Header.tsx';
import Footer from './components/Footer.tsx';
import Home from './pages/home.tsx';
import SpaAppartement from './pages/spa-appartement.tsx';
import SpaDomicile from './pages/spa-domicile.tsx';
import FAQ from './pages/faq.tsx';
import Contact from './pages/contact.tsx';
import Reservation from './pages/reservation.tsx';
import ReservationDomicile from './pages/reservation-domicile.tsx';
import ReservationAppartement from './pages/reservation-appartement.tsx';

function App() {
  return (
    <Router>
      <div>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/spa-domicile" element={<SpaDomicile />} />
          <Route path="/spa-appartement" element={<SpaAppartement />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/reservation" element={<Reservation />} />
          <Route path="/reservation-domicile" element={<ReservationDomicile />} />
          <Route path="/reservation-appartement" element={<ReservationAppartement />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
