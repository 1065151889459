import React from 'react';
import '../styles/main.css';

function ReservationDomicile() {
  return (
    <div className='iframe-reservation-domicile'>
      <iframe 
        src="https://docs.google.com/forms/d/e/1FAIpQLSea8wQ39FkpCg8gwMJHb8km5CAfKeTS8-EikuPWSbTV_GJIGA/viewform?embedded=true" 
        width="100%" 
        height="100%"
        title="Formulaire de réservation spa domicile"
        sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
      >
        Chargement…
      </iframe>

    </div>
  );
}

export default ReservationDomicile;