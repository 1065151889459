import React from 'react';
import { Accordion } from 'react-bootstrap';
import '../styles/faq.css';

function FAQ() {
  return (
    <div className='section'>
      <h1>Questions fréquentes</h1>
      <p>Vous ne trouvez pas la réponse à votre question ? N'hésitez pas à nous contacter via notre formulaire de contact, nous vous y répondrons dans les plus brefs délais.</p>
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>Quels sont les avantages  de la location d'un spa à domicile ?</Accordion.Header>
          <Accordion.Body>
            La location de spa à domicile offre la comodité de profiter d'une expérience de détente dans le confort de votre foyer, sans avoir à vous déplacer.
            Nous venons installer le spa à votre domicile, en intérieur comme en extérieur, pour la durée de votre choix et vous n'aurez plus qu'à vous détendre et profiter de ce moment.
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="1">
          <Accordion.Header>Que comprend la prestation ?</Accordion.Header>
          <Accordion.Body>
            Nos installateurs s'occupent de tout de A à Z, l'installation du spa et ses accessoires dans le lieu de votre choix, la désinfection du matériel, le remplissage du spa et la mise en place de la décoration si vous en souhaitez une. Ensuite, il ne vous restera plus qu'à profiter !
            <br />
            <br />
            Le remplissage de l'eau s'effectue grâce à une arrivée d'eau de votre domicile, et grâce à notre matériel adapté.
            <br />
            <br />
            A savoir :
            <br />
            Dans le cas où vous disposez d'un ballon d'eau chaude, l'installateur ne pourra pas remplir totalement le spa d'eau chaude à cause de la capacité du ballon en eau chaude. Le spa sera donc rempli également d'eau froide. Nous vous préconisons donc une installation en début de journée, afin que le spa chauffe l'eau et qu'elle soit à la température idéale en fin de journée (2-3 degrés par heure environ).
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="2">
          <Accordion.Header>Quel type de spa sera installé à mon domicile ?</Accordion.Header>
          <Accordion.Body>
            Nous installons à votre domicileun spa 4 places à bulles. Nos spas permettent de chauffer et de garder l'eau chaude jusqu'à 40 degrés durant tout le temps de la location, ce qui vous permettra un moment de pure détente dans votre salon ou encore dans votre espace extérieur.
            <br />
            Nos spas sont également équipés d'un système de filtration afin de conserver une eau propre.
            <br />
            <br />
            Le spa à un diamètre de 2 mètres, il vous faudra donc un espace de 4m² afin de pouvoir l'installer à votre domicile. Il se faufile donc parfaitement dans la plupart des intérieurs.
            <br />
            <br />
            L'installateur aura besoin d'un point d'eau (salle de bain, cuisine ou robinet extérieur) situé à moins de 20 mètres du spa. Nous aurons également besoin de la présence d'une prise murale classique à proximité.
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="3">
          <Accordion.Header>Quels sont les coûts de consommation électrique d'une soirée de spa à domicile ?</Accordion.Header>
          <Accordion.Body>
            Nos spas contiennent environ 600 litres d'eau, ce qui représente moins d'un mètre cube. Le surplus sur votre facture d'eau sera donc d'environ 3/4€ par rapport à votre consommation habituelle.
            <br />
            <br />
            L'électricité consommée par le spa pour 24h sera environ du même ordre de prix.
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="4">
          <Accordion.Header>Comment assurons-nous l'hygiène de nos spas ?</Accordion.Header>
          <Accordion.Body>
            Notre protocole d'hygiène est réalisé devant vos yeux : <br />
            <br />
            ◉ Les spas sont désinfectés à votre domicile lors de l'installation, afin d'assurer une propreté irréprochable. <br />
            ◉ Le filtre est neuf et changé à chaque prestation, il sera ouvert et mis en place devant vous. <br />
            ◉ L'eau sera filtrée durant toute la durée de la location. <br />
            ◉ L'installateur réalisera également un nettoyage complet du spa et du matériel au moment de la désinstallation à votre domicile. <br />
            <br />
            Nous accordons une grande importance au respect du protocole d'hygiène afin de vous assurer une expérience irréprochable.
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="5">
          <Accordion.Header>Puis-je annuler ou modifier ma réservation ?</Accordion.Header>
          <Accordion.Body>
            Oui, vous pouvez annuler ou modifier votre réservation, mais des conditions s'appliquent. <br />
            <br />
            En effet, lors de la validation de votre réservation, vous devez régler un acompte de 20€. Cet acompte ne sera pas remboursable en cas d'annulation de votre part. <br />
            <br />
            Vous pourrez modifier votre réservation, mais nous vous remercions de nous prévenir suffisament à l'avance afin que l'on arrive à vous proposer une autre date selon nos disponibilités. <br />
            <br />
            Veuillez noter que toute modification de réservation effectuée moins de 48 heures avant la date prévue ne pourra malheureusement pas être prise en compte. Nous vous prions de planifier vos ajustements à l'avance afin de garantir la meilleure expérience possible.
          </Accordion.Body>
        </Accordion.Item>

      </Accordion>
    </div>
  );
}

export default FAQ;