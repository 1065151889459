import React from 'react';

interface SupplementsCardProps {
  image: string;
  title: string;
  text: string;
  price: string;
}

const SupplementCard: React.FC<SupplementsCardProps> = ({ image, title, text, price }) => {
  return (
    <div className='supplement-card'>
      <div className='section-top'>
        <img className="img" src={image} alt={`Supplement Item`} />
      </div>
      <div className='section-bottom'>
        <h3>{title}</h3>
        <p>{text}</p>
        <p className='price'>{price}</p>
      </div>
    </div>
  );
};

export default SupplementCard;
