import React from 'react';
import '../styles/main.css';
import '../styles/accueil.css';
import { Badge, Button, Carousel } from 'react-bootstrap';
import ReviewsSection from '../components/ReviewsSection.tsx';

function Home() {
    return (
        <div>
            <Carousel interval={100000}>
                <Carousel.Item className="item1">
                    <div className="overlay-content">
                        <h1>
                            Bubbles Room
                            <Badge className='badge'>New</Badge>
                        </h1>
                    </div>
                </Carousel.Item>
                <Carousel.Item className="item2">
                    <div className="overlay-content">
                        <h1>Bubbles Island - Spa à domicile</h1>
                    </div>
                </Carousel.Item>
            </Carousel>

            <div className='section1'>
                <div className='logo'>
                    <img src='/logo.svg' alt="Logo Bubbles Island" />
                </div>
                <div className='txt1'>
                    <h1>Spa à Domicile & Appartement Privé</h1>
                    <p>
                        Bubbles Island vous convie à découvrir son service exclusif de location de spa à domicile dans toute la France, ainsi que ses appartements équipés de spa et sauna privés en banlieue parisienne. Notre sélection d'offres personnalisées vous propose le choix entre une expérience de détente chez vous ou dans nos appartements, où spa et sauna privés vous attendent. Immergez-vous dans une atmosphère de tranquillité et de bien-être absolu pour des instants mémorables.
                    </p>
                </div>
            </div>

            <div className='container2'>
                <div className='section2'>
                    <div className='txt2'>
                        <h1>Bien-être chez Soi</h1>
                        <p>
                            Offrez-vous un moment de détente sans pareil avec notre service de spa à domicile. Plongez dans une expérience de relaxation totale, où le confort de votre foyer se transforme en votre propre oasis de bien-être. Profitez d'une gamme de soins personnalisés et laissez nos professionnels vous offrir un moment inoubliable de tranquillité et de revitalisation, là où le luxe rencontre votre intimité.
                        </p>
                        <Button href="/spa-domicile" className="button bouton">En savoir +</Button>
                    </div>
                    <div className='img-spa'>
                        <img src='/images/spa-domicile.JPG' alt="Spa à domicile" />
                    </div>
                </div>
            </div>

            <div className='section3'>
                <div className='img-appart'>
                    <img src='/images/appart-spa.JPEG' alt="Spa à domicile" />
                </div>
                <div className='txt3'>
                    <h1>Espace privatif relaxant</h1>
                    {/* <Badge bg="primary">New</Badge> */}
                    <p>
                        Découvrez notre collection d'appartements avec spa privé, conçus pour vous offrir une évasion luxueuse et apaisante en banlieue parisienne. Plongez dans une atmosphère de confort ultime où chaque espace est pensé pour votre bien-être. Profitez d'un refuge exclusif où détente et intimité se rencontrent, vous permettant de vous ressourcer dans un cadre raffiné et relaxant. Laissez-vous choyer par nos équipements de spa haut de gamme pour une expérience inégalée.
                    </p>
                    <Button href="/spa-appartement" className="button bouton">En savoir +</Button>
                </div>
            </div>

            <div className="section4">
                <h1>Retours de nos clients</h1>
                <ReviewsSection />
            </div>

            <div className="section5">
                <Button className='button bouton-res' href='/reservation'>Réserver dès maintenant</Button>
            </div>
        </div>
    );
}

export default Home;