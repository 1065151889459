import React from 'react';
import '../styles/entites.css';
import { Button, Card, Carousel } from 'react-bootstrap';
import ReviewsSection from '../components/ReviewsSection.tsx';
import SupplementsSection from '../components/SupplementsSectionDomicile.tsx';

function SpaDomicile() {
  return (
    <div>
      <div className='div1'>
        <div className='txt-div1'>
          <h1>Spa à domicile</h1>
          <p>
            Offrez-vous un moment de détente sans pareil avec notre service de spa à domicile. Plongez dans une expérience de relaxation totale, où le confort de votre foyer se transforme en votre propre oasis de bien-être. Profitez d'une gamme de soins personnalisés et laissez nos professionnels vous offrir un moment inoubliable de tranquillité et de revitalisation, là où le luxe rencontre votre intimité.
          </p>
        </div>
        <div>
          <Carousel className='carousel-div' interval={3000}>
            <Carousel.Item className="item carousel-spa1" />
            <Carousel.Item className="item carousel-spa2" />
            <Carousel.Item className="item carousel-spa3" />
            <Carousel.Item className="item carousel-spa4" />
            <Carousel.Item className="item carousel-spa5" />
          </Carousel>
        </div>
      </div>

      <div className='container2'>
        <div className='div2'>
          <div className='tarifs'>
            <h1>Nos tarifs</h1>
            <p>* Hors frais kilométriques selon la ville d'installation</p>
          </div>
          <div className='cards'>
            <Card className='card'>
              <Card.Body>
                <div className='blue'>
                  <Card.Title className='title'>La semaine</Card.Title>
                  <Card.Subtitle className="subtitle">Du Lundi au Jeudi (inclus)</Card.Subtitle>
                </div>
                <Card.Text className='txt-card'>
                  ◉ 80€ les 24h <br />
                  ◉ 130€ les 48h
                  <br /><br />
                  Décoration simple : 20€
                  <br />
                  Décoration VIP : 40€
                </Card.Text>
              </Card.Body>
            </Card>
            <Card>
              <Card.Body>
                <div className='blue'>
                  <Card.Title className='title'>Le week-end</Card.Title>
                  <Card.Subtitle className="subtitle">A partir du Vendredi</Card.Subtitle>
                </div>
                <Card.Text className='txt-card'>
                  ◉ 100€ les 24h <br />
                  ◉ 170€ les 48h
                  <br /><br />
                  Décoration simple : 20€
                  <br />
                  Décoration VIP : 40€
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>

      <div className='div3'>
        <h1>Nos suppléments</h1>
        <SupplementsSection/>
      </div>

      <div className="div4 div4-domicile">
        <h1>Retours de nos clients</h1>
        <ReviewsSection />
      </div>

      <div className="div5">
        <Button className='button bouton-res' href='/reservation-domicile'>Réserver dès maintenant</Button>
      </div>
    </div>
  );
}

export default SpaDomicile;