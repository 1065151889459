import React, { useEffect, useState } from 'react';
import ReviewCard from './ReviewCard.tsx';
import '../styles/components/avis.css';
import '../styles/main.css';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';

const ReviewsSection: React.FC = () => {
    const reviewsData = [
        {
            avatar: '../../images/avatar/S',
            rating: 5,
            comment: 'Merci Bubbles Island pour sa disponibilité de dernière minute qui a répondu à nos attentes, avec son équipe professionnelle, à l\'écoute, rapide et très efficace ! Très belle expérience et pas la dernière.',
        },
        {
            avatar: '../../images/avatar/C.png',
            rating: 5,
            comment: 'C\'était top. Nous avons passé une excellente soirée grâce à vous. Je pense que l\'on se reverra très vite. Ca nous a fait beaucoup de bien.',
        },
        {
            avatar: '../../images/avatar/E.jpg',
            rating: 4,
            comment: 'Hormis le fait que le jacuzzi semblait bloqué à 40°C (ma femme adore la chaleur et pour elle quelques degrès en plus n\'auraient pas été de refus) c\'était impeccable... Un grand merci à vous !',
        },

        {
            avatar: '../../images/avatar/R.jpg',
            rating: 5,
            comment: 'Merci beaucoup. Surtout pour votre bienveillance et votre rapidité !',
        },
        {
            avatar: '../../images/avatar/N.jpeg',
            rating: 5,
            comment: 'Je suis très contente du jacuzzi. Je vais pouvoir me détendre c\'est top, de plus l\'installeur m\'a tout montré en avance aussi donc c\'est super meri pour les infos et pour tout.',
        },
        {
            avatar: '../../images/avatar/M.jpeg',
            rating: 5,
            comment: 'Je vous remercie infiniment, tout est parfait, l\'installation s\'est très bien passée. Malgrès que ce soit une demande de dernière minute, vous avez assuré et nous sommes satisfait de votre prestation. Ca ne sera pas la dernière fois que je ferai appel à vous.',
        },
    ];

    const [startIndex, setStartIndex] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(3); // Nombre d'avis par page

    const nextReviews = () => {
        setStartIndex((prevIndex) => {
            const newIndex = prevIndex + itemsPerPage;
            return newIndex >= reviewsData.length ? 0 : newIndex;
        });
    };

    const prevReviews = () => {
        setStartIndex((prevIndex) => {
            const newIndex = prevIndex - itemsPerPage;
            if (newIndex < 0) {
                const remainder = reviewsData.length % itemsPerPage;
                return remainder === 0 ? reviewsData.length - itemsPerPage : reviewsData.length - remainder;
            }
            return newIndex;
        });
    };

    const visibleReviews = reviewsData.slice(startIndex, startIndex + itemsPerPage).map((review, index) => (
        <ReviewCard key={index} {...review} />
    ));

    useEffect(() => {
        function handleResize() {
            if (window.innerWidth <= 768) {
                setItemsPerPage(1); // Téléphones
            } else {
                setItemsPerPage(3); // Ordinateurs
            }
        }

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className="reviews-section">
            <FaArrowLeft onClick={prevReviews} />
            {visibleReviews}
            <FaArrowRight onClick={nextReviews} />
        </div>
    );
};

export default ReviewsSection;