import React, { useState } from 'react';
import RatingStars from './RatingStars.tsx';

interface ReviewCardProps {
  avatar: string;
  rating: number;
  comment: string;
}

const ReviewCard: React.FC<ReviewCardProps> = ({ avatar, rating, comment }) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <div className={`review-card ${expanded ? 'expanded' : ''}`}>
      <div className='section-top'>
        <img src={avatar} alt={`Avatar`} />
        <div className="rating">
          <RatingStars rating={rating} />
        </div>
      </div>
      <p>{expanded ? comment : `${comment.slice(0, 100)}...`}</p>
      {!expanded && (
        <div className="read-more">
          <span className="read-more-text" onClick={toggleExpand}>Lire la suite</span>
        </div>
      )}
    </div>
  );
};

export default ReviewCard;
