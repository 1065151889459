import React from 'react';
import '../styles/components/footer.css';
import '../styles/main.css';

function Footer() {
  return (
    <div className='body'>
        <p className='text-align'>© 2024 Bubbles Island - Tous droits réservés</p>
        <div className="social-icons">
            <a href="https://www.tiktok.com/@bubbles.island"><img src="/images/icons/tiktok.svg" alt="Tiktok" /></a>
            <a href="https://www.instagram.com/bubbles.island/"><img src="/images/icons/instagram.svg" alt="Instagram" /></a>
            <a href="https://www.facebook.com/share/KS7RqYPqLUBm6JkD/?mibextid=LQQJ4d"><img src="/images/icons/facebook.svg" alt="LinkedIn" /></a>
            <a href="https://www.snapchat.com/add/bubbles.island"><img src="/images/icons/snapchat.svg" alt="Snapchat" /></a>
      </div>
    </div>
  );
}

export default Footer;