import React from 'react';
import '../styles/entites.css';
import { Button, Card, Carousel } from 'react-bootstrap';
import SupplementsSection from '../components/SupplementsSectionAppart.tsx';
// import ReviewsSection from '../components/ReviewsSection.tsx';

function SpaAppartement() {
  return (
    <div>
      <div className='div1'>
        <div className='txt-div1'>
          <h1>Appartement spa</h1>
          <p>
          Découvrez notre appartement privé tout équipé avec spa et sauna dans les Yvelines. Il est situé à 15 minutes de Paris en voiture, à la sortie de l’A86, et à côté du RER A. Plongez dans une atmosphère de confort ultime où chaque espace est pensé pour votre bien-être. Profitez d'un refuge exclusif où détente et intimité se rencontrent, vous permettant de vous ressourcer dans un cadre raffiné et relaxant. Laissez-vous choyer par nos équipements de spa haut de gamme pour une expérience inégalée.
          </p>
        </div>
        <div>
          <Carousel className='carousel-div' interval={3000}>
            <Carousel.Item className="item carousel-appart1" />
            <Carousel.Item className="item carousel-appart2" />
            <Carousel.Item className="item carousel-appart3" />
            <Carousel.Item className="item carousel-appart4" />
            <Carousel.Item className="item carousel-appart5" />
            <Carousel.Item className="item carousel-appart6" />
          </Carousel>
        </div>
      </div>

      <div className='container2'>
        <div className='div2'>
          <div className='tarifs'>
            <h1>Nos tarifs</h1>
            <p>Arrivée possible à partir de 15h, départ avant 12h.</p>
          </div>

          <div className='cards'>
            <Card className='card'>
              <Card.Body>
                <div className='blue'>
                  <Card.Title className='title'>La semaine</Card.Title>
                  <Card.Subtitle className="subtitle">
                    Du Lundi au Jeudi (inclus)
                  </Card.Subtitle>
                  <Card.Title className='price'>150€</Card.Title>
                </div>
                <Card.Text className='txt-card'>
                  Décoration romantique : 30€
                </Card.Text>
              </Card.Body>
            </Card>
            <Card>
              <Card.Body>
                <div className='blue'>
                  <Card.Title className='title'>Le week-end</Card.Title>
                  <Card.Subtitle className="subtitle">
                    A partir du Vendredi
                  </Card.Subtitle>
                  <Card.Title className='price'>180€</Card.Title>
                </div>
                <Card.Text className='txt-card'>
                  Décoration romantique : 30€
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>

      <div className='div3'>
        <h1>Nos suppléments</h1>
        <SupplementsSection />
      </div>

      <div className="div4 div4-appart">
        <h1>Retours de nos clients</h1>
        <p>Soyez le premier à nous donner votre avis via nos réseaux sociaux.</p>
      </div>

      <div className="div5">
        <Button className='button bouton-res' href='/reservation-appartement'>Réserver dès maintenant</Button>
      </div>
    </div>
  );
}

export default SpaAppartement;